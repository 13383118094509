import React from "react"
import { graphql } from 'gatsby'

const Charities = ({ node }) =>
{
	return (
    <section id="section-charities" className="section-body">
      <div className="content">
        <div className="content--line"></div>
        <div className="content--main">
          <p className="eyebrow">EMPLOYEE-NOMINATED CHARITIES</p>
          <div className="grid-2">
            <div className="item">
							<span dangerouslySetInnerHTML={{__html: node.content}} />
            </div>
						{node.featured_media &&
            <div className="item featured-image">
							<img className="image-responsive" src={node.featured_media.localFile.childCloudinaryAsset.fluid.src} alt={node.featured_media.alt_text} />
            </div>
						}
          </div>
          <div className="grid-2 rtl">
          {
						node.acf.employee_nominated_charities.length > 0 &&
							node.acf.employee_nominated_charities.map( (charity, i) => {
								return[
									<div key={i} className="item">
										<img className="image-responsive" src={charity.charity_logo.localFile.childCloudinaryAsset.fluid.src} alt={charity.charity_logo.alt_text} />
									</div>,
									<div key={i+10} className="item">
										<span dangerouslySetInnerHTML={{__html: charity.about_the_charity}} />
										<a href={charity.charity_website.url} target={charity.charity_website.target} className="button-flat">{charity.charity_website.title} <i className="fas fa-arrow-right"></i></a>
									</div>
								]
							})
          }
          </div>
        </div>
      </div>
    </section>
	)
}
export default Charities

export const charitiesQuery = graphql`
  	fragment Charities on wordpress__PAGE {
			content
			featured_media{
				localFile{
					childCloudinaryAsset{
						fluid{
							...CloudinaryAssetFluid
						}
					}
				}
				alt_text
	    	}
			acf {
	  	  	employee_nominated_charities {
				charity_name
				about_the_charity
				charity_website {
					title
					url
					target
				}
	        	charity_logo {
					alt_text
	          		localFile{
						childCloudinaryAsset{
							fluid{
								...CloudinaryAssetFluid
						}
	            	}
	          	}
	        }
	  	  }
	  	}
    }
`
