import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import FlexibleContent from '../../../components/FlexibleContent';
import Layout from '../../../layouts';
import { pageTitle } from '../../../utils/helpers';
import Charities from '../../../components/Charities';
import SEO from '../../../components/seo/SEO';
import { useSiteMetadata } from "../../../hooks/use-site-metadata";

// import GivingBackFormBg from "../../../images/giving-back-contact-bg.jpg"

export default function GivingBack(props) {
  useEffect(() => {
    $('#giving-back-form').submit(function(e) {
      e.preventDefault();

      var $form = $(this);
      $.post($form.attr('action'), $form.serialize()).then(function() {
        $form.trigger('reset');
        $('#form-message').html(
          'Your message was sent successfully. Thank you!'
        );
      });
    });
  });
  
  const currentPage = props.data.wordpressPage;
  const site = useSiteMetadata();

  const currentPageTitle = (currentPage.yoast_meta.yoast_wpseo_title) ? currentPage.yoast_meta.yoast_wpseo_title : pageTitle({
    site: site.title,
    page: currentPage
  });

  return (
    <Layout>
      <Helmet bodyAttributes={{ id: `page-${currentPage.slug}` }}>
        <title>
          {currentPageTitle}
        </title>
      </Helmet>
      <SEO node={currentPage} site={site} />
      <FlexibleContent currentPage={currentPage} />
      <section
        className="section-body"
        style={{ backgroundColor: '#151A20', color: '#ffffff' }}
      >
        <div className="content">
          <div className="content--main">
            <h4 className="text-align-center">{`Let’s connect and make an impact`}</h4>
            <p>{`If you're interested in coming together for our collective good, send us a message. Whether it's for pro bono work or potential volunteer service, we want to hear from you. Start by filling out our form.`}</p>
            <form
              id="giving-back-form"
              name="giving-back"
              method="post"
              data-netlify="true"
              /* data-netlify-recaptcha="true" */
              data-netlify-honeypot="bot-field"
            >
              <div className="form-fields">
                <div className="form-field">
                  <label htmlFor="et_pb_contact_name_1">Name</label>
                  <input
                    type="text"
                    id="et_pb_contact_name_1"
                    placeholder="Your name*"
                    name="et_pb_contact_name_1"
                    autoComplete="name"
                    required
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="et_pb_contact_email_1">Email Address</label>
                  <input
                    type="text"
                    id="et_pb_contact_email_1"
                    placeholder="Email address*"
                    name="et_pb_contact_email_1"
                    autoComplete="email"
                    required
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="et_pb_contact_organization_1">
                    Name of organization
                  </label>
                  <input
                    type="text"
                    id="et_pb_contact_organization_1"
                    placeholder="Name of organization"
                    name="et_pb_contact_organization_1"
                    autoComplete="organization"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="et_pb_contact_organization_website_1">
                    Organization URL
                  </label>
                  <input
                    type="text"
                    id="et_pb_contact_organization_website_1"
                    placeholder="Organization URL"
                    name="et_pb_contact_organization_website_1"
                  />
                </div>
              </div>
              <label htmlFor="et_pb_contact_description_1">
                Brief description of your organization
              </label>
              <textarea
                name="et_pb_contact_description_1"
                id="et_pb_contact_description_1"
                placeholder="Brief description of your organization"
              />

              <label htmlFor="et_pb_contact_why_support_1">
                Why you need support
              </label>
              <textarea
                name="et_pb_contact_why_support_1"
                id="et_pb_contact_why_support_1"
                placeholder="Why you need support"
              />
              <span className="red">* Required</span>
              <div data-netlify-recaptcha="true" />
              <div id="form-message" />
              <button id="submit" type="submit">
                Send
              </button>
              <input type="hidden" name="form-name" value="giving-back" />
              <input type="hidden" name="bot-field" />
              {/* <input type="hidden" name="g-recaptcha-response" /> */}
            </form>
          </div>
        </div>
      </section>
      <Charities node={currentPage} />
    </Layout>
  );
  
}

export const pageQuery = graphql`
  {
    wordpressPage(title: { eq: "Giving Back" }) {
      title
      slug
      ...Hero
      ...Info
      ...Grid
      ...Interstitial
      ...Charities
      ...SEO
    }
  }
`;
